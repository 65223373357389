import { usePage } from '@inertiajs/react';

export default () => {
  const {
    application: { user },
  } = usePage().props;

  user.showPrices = user?.showPrices && !user.customer.hide_prices;

  if (user) {
    user.isAdmin = ['admin', 'developer'].includes(user.role.value) || ['admin', 'developer'].includes(user.role);
    user.isRep = ['rep'].includes(user.role.value) || ['rep'].includes(user.role);
  }

  return user;
};
